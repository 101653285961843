import { PartnerConfig } from "src/app/core/interfaces/partner-config";

export const Partner: PartnerConfig = {
  id: 'test',
  title: {
    de: 'Sysbiz Test Portal [de]',
    it: 'Sysbiz Test Portal [it]'
  },
  portalUrl: 'https://test-bs.systems.bz',
  apiUrl: 'https://test-bs-api.systems.bz',
  oldPortalUrl: 'https://test-bs.systems.bz/-/',
  backlink: true,
  pathPrefix: ''
}
