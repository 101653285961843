import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): any {
    try {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
    } catch (error) {
      return value;
    }
  }

}
