import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { Partner } from 'src/app/config/partner/_default';

@Injectable({
  providedIn: 'root'
})
export class CustomTitleService {
  private title: string;
  private translate: boolean;

  constructor(
    private titleService: Title,
    private translation: TranslateService
  ) {
    // Detect language change and renew title
    translation.onLangChange.subscribe((translation) => {
      this.setTitle(this.title, this.translate);
    });
  }

  /**
   * Set the title
   * @param title Page title
   * @param translate Page title must be translated first
   */
  public setTitle(title?: string, translate?: boolean) {
    // Cache method call for language change detection
    this.title = title;
    this.translate = translate;

    if (translate === true)
      this.translation.onLangChange.subscribe(() => {
        this.translation.get(title).subscribe(
          (res: string) => {
            this.titleService.setTitle(this.concatTitle(res));
          }
        )
      });
    else
      this.titleService.setTitle(this.concatTitle(title));
  }

  /**
   * Concatenate page title with app title
   * @param pageTitle Page title
   */
  private concatTitle(pageTitle?: string): string {
    let title: string;
    let titleAppendix: string = Partner.title[this.translation.currentLang.substr(0, 2)];

    // Concatenate titles;
    if (pageTitle)
      title = `${pageTitle} | ${titleAppendix}`;
    else
      title = titleAppendix;

    return title;
  }

  /**
   * Return current title
   */
  public getTitle(): string {
    return this.titleService.getTitle();
  }
}
