import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth';
import { CustomerService } from '../customer/customer-client/customer.service';
import { CompanyContract, ContractService } from '../customer/contract.service';
import { PagedResultDto } from '@systems/base';

@Component({
  selector: 'app-goto-startpage',
  templateUrl: './goto-startpage.component.html',
  styleUrls: ['./goto-startpage.component.scss']
})
export class GotoStartpageComponent implements OnInit {

  constructor(    
    private authService: AuthService,
    private contractService: ContractService,
    private router: Router) { }

    ngOnInit() {
      this.checkAndNavigateToOpenContracts();
    }

    checkAndNavigateToOpenContracts() {
      this.authService.rbac(['CustomersReadAll', 'SystemsAdmin']).then(authorized => {
        if (authorized) {
          this.contractService.listOpenContracts().subscribe(x => {
            if (x.results.length > 0) {
              this.router.navigate(['/dashboard']);
            } else {
              this.checkAdditionalModuleAndNavigate();
            }
          });
        } else {
          this.checkAdditionalModuleAndNavigate();
        }
      });
    }
    checkAdditionalModuleAndNavigate() {
      this.authService.rbac(['area1z', 'area28z', 'areaNSO']).then(authorized => {
        if (authorized) {
          this.router.navigate(['/invoices/dashboard']);
        } else {
          this.authService.rbac(['consv3-area1']).then(authorized => {
            if (authorized) {
              this.router.navigate(['/archive/dashboard']);
            } else {
              this.authService.rbac(['BANKING.READ', 'BANKING.AUTOMATIC']).then(authorized => {
                if (authorized) {
                  this.router.navigate(['/banking']);
                } else {
                  this.authService.rbac(['DOCUMENTSIGNER.CREATE']).then(authorized => {
                    if (authorized) {
                      this.router.navigate(['/document-signer']);
                    } else {
                      // User not authorized → reroute to login page
                      this.authService.redirectToLogin();
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
}
