import { HttpClient, HttpParams, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDetailsApi, IListApi, PagedResultDto, RestClientService } from '@systems/base';
import { map, Observable } from 'rxjs';
import { CONSERVATIONAPICLIENT_CONFIG } from '../config-loader.service';
import { TOKEN_NAME } from '../sysbiz.service';
import { DocumentDto } from './document.service';
import { BaseConservationDocumentTemplate } from './models/base-conservation-document-template';
import { Translation, TranslationDto } from './models/translation';

@Injectable({
  providedIn: 'root'
})
export class DocTypeService extends RestClientService implements IListApi<DocumentTypeDto>, IDetailsApi<DocumentTypeDto> {

  constructor(
    http: HttpClient,
  ) {
    super(CONSERVATIONAPICLIENT_CONFIG, http);
  }

  list(pageNumber?: number, pageSize?: number): Observable<PagedResultDto<DocumentTypeDto>> {
    throw new Error('Method not implemented.');
  }

  public listByFilters(includeTemplate?: boolean): Observable<PagedResultDto<DocumentTypeDto>> {

    let params = new HttpParams();

    params = params.append('includeTemplate', includeTemplate as any);

    return this.http.get<PagedResultDto<DocumentTypeDto>>(this.getUrl('docTypes'),
      {
        headers: {
          'authenticate': this.Token
        },
        params: params,
        observe: 'response'
      })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }


  public get(guid: string): Observable<DocumentTypeDto> {

    if (guid === null || guid === undefined) {
      throw new Error('Required parameter documentTypeGuid was null or undefined when calling GetDocTypes.');
    }

    return this.http.get<DocumentDto>(this.getUrl('docTypes/' + guid),
      {
        headers: {
          'authenticate': this.Token
        },
        observe: 'response'
      })
      .pipe(map(resp => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  /**
   * Get current user token
   */
   private get Token(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  /**
   * Update the token from the http responce
   * @param response api response
   */
   private updateTokenFromResponse(response: HttpResponseBase) {
    let newToken = response.headers.get('authenticate');
    if (newToken)
      localStorage.setItem(TOKEN_NAME, newToken);
  }
}

export interface DocumentTypeDto extends Translation {
  guid?: string;
  active?: boolean;
  startArchiveInHours?: number;
  documentInfoTemplate?: BaseConservationDocumentTemplate;
  translations?: TranslationDto[];
  shortDescription?: string;
}
