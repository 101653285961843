import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CompanyDto, CompanyService, CompanySortingDto } from 'src/app/core/customer/customer-client/company.service';
import { Observable, of } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, switchMap, catchError, filter } from 'rxjs/operators';
import { SortDirectionDto } from 'src/app/core/customer/customer-client/sort-direction';
import { AuthService } from 'src/app/core';
import { PagedResultDto } from '@systems/base';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {

  @Input() public selectedCompanyDisplay: string;
  @Input() public selectedCompanyGuid: string;

  @Output() onChangeCompany: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeCompanyDisplayName: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchField') searchField: ElementRef;

  public companies: PagedResultDto<CompanyDto>;
  public hasOnlyOneCompany: boolean = false;
  public dropdownEnabled: boolean = false;

  constructor(
    public companyService: CompanyService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.companyService.getCompanyCount().subscribe(count => {
      if (count <= 15 && count != 1) {
        this.hasOnlyOneCompany = false;
        this.dropdownEnabled = true;
        this.companyService.listByFilters().subscribe(companies => {
          this.companies = companies;
        });
      } else if (count == 1) {
        this.hasOnlyOneCompany = true;
        this.dropdownEnabled = false;
        if (this.hasOnlyOneCompany && !this.authService.User.SelectedCompanyUid) {
          this.companyService.listByFilters().subscribe(companies => {
            this.onChangeCompany.emit(companies[0].uid);
          });
        }
      }
    });
  }

  public inputFormatter = () => null;

  public resultFormatter = (result: CompanyDto) => {
    var returnvaldefault = result.datiAnagrafici.idFiscaleIVA.idPaese + result.datiAnagrafici.idFiscaleIVA.idCodice + ' - ' + (result.searchName);
    return returnvaldefault;
  };

  public search = (query$: Observable<string>) =>
    query$.pipe(
      filter(term => term.trim().length >= 3),
      distinctUntilChanged(),
      debounceTime(400),
      switchMap(
        term =>
          this.companyService.listByFilters(CompanySortingDto.SearchName, SortDirectionDto.ascending, term, true, 1, 15)
            .pipe(
              map(results => {
                return results.results;
              }),
              catchError(() => of([]))
            )
      )
    )

  public updateSearchText(target: EventTarget | null) {
    if (target === null) {
      return;
    }
    this.selectedCompanyDisplay = (target as HTMLInputElement).value;
  }

  public cancelSearch(): void {
    if (this.searchField.nativeElement.value == '' && this.selectedCompanyDisplay) {
      this.resetSearch();
    } else if (this.searchField.nativeElement.value != '') {
      this.searchField.nativeElement.value = this.selectedCompanyDisplay;
    }
  }

  public resetSearch(): void {
    this.selectedCompanyDisplay = '';
    this.onChangeCompany.emit(null);
  }

  public updateSelectedCompany(selectedCompanyGuid: string) {
    var foundCompany = this.companies.results.filter(x => x.guid == selectedCompanyGuid)[0];
    if (foundCompany && this.authService.User && this.authService.User.SelectedCompanyUid !== foundCompany.guid) {
      this.onChangeCompany.emit(foundCompany.guid);
      this.onChangeCompanyDisplayName.emit(foundCompany.searchName);
      this.selectedCompanyDisplay = foundCompany.searchName;
    }
    else if(!foundCompany && this.authService.User){
      this.onChangeCompany.emit(null);
      this.onChangeCompanyDisplayName.emit(null);
      this.selectedCompanyDisplay = null;
    }
  }
  protected updateSelectedCompanyBySearch(selectedCompany: CompanyDto) {
    this.selectedCompanyDisplay = null;
    if (this.authService.User && this.authService.User.SelectedCompanyUid !== selectedCompany.guid) {
      this.onChangeCompany.emit(selectedCompany.guid);
      this.onChangeCompanyDisplayName.emit(selectedCompany.searchName);
    }
    this.selectedCompanyDisplay = selectedCompany.searchName;
  }


}
