import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { NavigationMainComponent } from "./components/navigation-main/navigation-main.component";
import { NavigationSidebarComponent } from './components/navigation-sidebar/navigation-sidebar.component';
import { QualityStatusComponent } from './components/quality-status/quality-status.component';
import { MessageComponent } from './components/message/message.component';
import { SortHeaderComponent } from './components/sort-header/sort-header.component';
import { ValidationComponent } from './components/validation/validation.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';

// Directives
import { OcsDirective, RbacDirective } from "./directives";
import { IconComponent } from './components/icon/icon.component';

// Pipes
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomNumberPipe } from './pipes/custom-number.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { PresuffixPipe } from './pipes/presuffix.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { FormsModule } from '@angular/forms';
import { RbacPipe } from './pipes/rbac.pipe';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { MainComponent } from './main/main.component';
import { AngularBaseModule } from '@systems/base';
import { NewsFeedComponent } from './components/news-feed/news-feed.component';
import { FooterComponent } from './footer/footer.component';
import { ChildListNumberCompletenessComponent } from './components/number-completeness-check/child-list-number-completeness/child-list-number-completeness.component';
import { NumberCompletenessSidebarComponent } from './components/number-completeness-check/components/number-completeness-sidebar/number-completeness-sidebar.component';
import { DetailsNumberCompletenessComponent } from './components/number-completeness-check/details-number-completeness/details-number-completeness.component';
import { DisableNumberCompletenessCheckComponent } from './components/number-completeness-check/disable-number-completeness-check/disable-number-completeness-check.component';
import { EditNumberCompletenessComponent } from './components/number-completeness-check/edit-number-completeness/edit-number-completeness.component';
import { ListNumberCompletenessComponent } from './components/number-completeness-check/list-number-completeness/list-number-completeness.component';
import { NumberCompletenessCheckComponent } from './components/number-completeness-check/number-completeness-check.component';
import { ConfirmRunNumberCompletenessComponent } from './components/number-completeness-check/confirm-run-number-completeness/confirm-run-number-completeness.component';
import { EntitySearchComponent } from './components/entitySearch/entity-search.component';
import { CustomTimePipe } from './pipes/custom-time.pipe';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';

@NgModule({
  imports: [
    AngularBaseModule,
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    FormsModule
  ],
  declarations: [
    NavigationMainComponent,
    NavigationSidebarComponent,
    QualityStatusComponent,
    IconComponent,
    MessageComponent,
    SortHeaderComponent,
    EntitySearchComponent,

    OcsDirective,
    RbacDirective,

    CustomDatePipe,
    CustomTimePipe,
    CustomNumberPipe,
    CapitalizePipe,
    CustomCurrencyPipe,
    EmptyPipe,
    PresuffixPipe,
    OrderByPipe,
    RbacPipe,
    ValidationComponent,
    DatePickerComponent,
    CurrencySymbolPipe,
    MainComponent,
    NewsFeedComponent,
    FooterComponent,
    NumberCompletenessCheckComponent,
    ListNumberCompletenessComponent,
    EditNumberCompletenessComponent,
    DetailsNumberCompletenessComponent,
    ChildListNumberCompletenessComponent,
    NumberCompletenessSidebarComponent,
    DisableNumberCompletenessCheckComponent,
    ConfirmRunNumberCompletenessComponent,
    CompanySelectorComponent
  ],
  exports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    EntitySearchComponent,

    NavigationMainComponent,
    NavigationSidebarComponent,
    QualityStatusComponent,
    SortHeaderComponent,
    ValidationComponent,
    DatePickerComponent,

    OcsDirective,
    RbacDirective,
    IconComponent,
    MessageComponent,

    CustomDatePipe,
    CustomTimePipe,
    CustomNumberPipe,
    CapitalizePipe,
    CustomCurrencyPipe,
    EmptyPipe,
    PresuffixPipe,
    OrderByPipe,
    RbacPipe,
    CurrencySymbolPipe,

    MainComponent,
    NewsFeedComponent,
    NumberCompletenessCheckComponent,
    ListNumberCompletenessComponent,
    EditNumberCompletenessComponent,
    DetailsNumberCompletenessComponent,
    ChildListNumberCompletenessComponent,
    NumberCompletenessSidebarComponent,
    DisableNumberCompletenessCheckComponent,
    CompanySelectorComponent
  ],
  providers: [
    CustomDatePipe,
    CustomTimePipe,
  ]
})
export class SharedModule { }