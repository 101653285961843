// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-button::after {
  content: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/number-completeness-check/details-number-completeness/details-number-completeness.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".accordion-button::after {\n    content: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
