import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarToggleService } from './sidebar-toggle.service';

@Component({
  selector: 'app-navigation-sidebar',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss']
})
export class NavigationSidebarComponent {
  @Input() class: string;
  public isMenuCollapsed: boolean;
  private subscription: Subscription;

  constructor(public sidebar: SidebarToggleService) {
    this.subscription = sidebar.GetValue().subscribe(value => {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    })
  }

  ngOnInit() {
    this.isMenuCollapsed = this.sidebar.isMenuCollapsed;
  }

}
