import { Component, OnInit, HostListener } from '@angular/core';
import { RequestService } from 'src/app/core/request/request.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core';
import { Partner } from 'src/app/config/partner/_default';
import { SysbizAuthService } from 'src/app/core/sysbiz/sysbiz-auth.service';
import { SidebarToggleService } from '../navigation-sidebar/sidebar-toggle.service';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/core/customer/customer-client/company.service';


@Component({
  selector: 'app-navigation-main',
  templateUrl: './navigation-main.component.html',
  styleUrls: ['./navigation-main.component.scss']
})
export class NavigationMainComponent implements OnInit {

  public selectedCompanyDisplay: string = null;
  public selectedCompanyGuid: string = null;

  readonly Partner = Partner;
  public companyName: String;
  public showNavbar = false;
  public showUsermenu = false;
  public languages: Array<string>;
  public checkPermission: boolean = false;
  public authToken: string;
  public isMenuCollapsed: boolean = true;
  public isSmallScreen = false;

  constructor(
    public requests: RequestService,
    public translate: TranslateService,
    public authService: AuthService,
    private companyService: CompanyService,
    private sysBizAuthService: SysbizAuthService,
    public sidebar: SidebarToggleService,
    public router: Router
  ) {

  }

  public getCurrentHelpRoute(): string {
    let routeParts = this.router.url.split(/[/\?]/);
    return routeParts.slice(0, 2).join("/") + "/help";
  }


  toggle() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.sidebar.toggle()
  }

  ngOnInit() {
    this.isMenuCollapsed = this.sidebar.isMenuCollapsed;
    this.getLanguages();
    this.translate.onLangChange.subscribe(() => this.getLanguages());
    this.isSmallScreen = window.innerWidth <= 1850; // Change the breakpoint as needed


    this.authService.subscribeUser().subscribe(x => {
      if (this.authService.User) {
        this.authToken = encodeURIComponent(this.sysBizAuthService.Token);
        this.selectedCompanyGuid = this.authService.User.SelectedCompanyUid;

        this.CheckUserRole(this.authService.User.Roles);
        if (this.selectedCompanyGuid) {
          this.companyService.get(this.selectedCompanyGuid).subscribe(x => {
            if (x.searchName === null || x.searchName === undefined || x.searchName === "") {
              this.selectedCompanyDisplay = x.datiAnagrafici.anagrafica.nome + " " + x.datiAnagrafici.anagrafica.cognome;
            } else {
              this.selectedCompanyDisplay = x.searchName;
            }
          });
        }
      }
    });
  }

  public toggleNavbar(shown: boolean = null) {
    if (shown !== null) {
      this.showNavbar = shown;
    } else {
      this.showNavbar = !this.showNavbar;
      if (this.showUsermenu) {
        this.toggleUsermenu(false);
      }
    }
  }

  public toggleUsermenu(shown: boolean = null) {
    if (shown !== null) {
      this.showUsermenu = shown;
    } else {
      this.showUsermenu = !this.showUsermenu;
      if (this.showNavbar) {
        this.toggleNavbar(false);
      }
    }
  }

  public getLanguages(): void {
    this.languages = this.translate.getLangs().filter(lang => lang !== this.translate.currentLang);
  }

  public changeLanguage(locale: string): void {
    this.translate.use(locale);

    localStorage.setItem('locale', locale);
    this.toggleUsermenu(false);
  }

  public logout(): void {
    this.authService.logout();
  }

  public changeCompany(guid: string) {
    this.authService.User.SelectedCompanyUid = guid;
    this.selectedCompanyGuid = guid;
    if(guid)
    {
      this.companyService.get(this.selectedCompanyGuid).subscribe(x => {
        if (x.searchName === null || x.searchName === undefined || x.searchName === "") {
          this.selectedCompanyDisplay = x.datiAnagrafici.anagrafica.nome + " " + x.datiAnagrafici.anagrafica.cognome;
        } else {
          this.selectedCompanyDisplay = x.searchName;
        }
      });
    }
    else{
      this.selectedCompanyDisplay = null;
      this.selectedCompanyDisplay = null;
    }

    const callSave$ = this.sysBizAuthService.updateSelectedCompany(guid);
    this.requests.addRequest('Update-Usersettings', callSave$).subscribe(() => { });
  }

  private CheckUserRole(rolearr: string[]): void {
    //check userrole area2 exists in the user role
    for (var i = 0; i < rolearr.length; i++) {
      if (rolearr[i] == "area2") {
        this.checkPermission = true
      }
    }
  }

  public get username(): string {
    return this.authService.User?.FullName;
  }

  // Add this HostListener function to listen for window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth <= 1850; // Change the breakpoint as needed
  }
}
