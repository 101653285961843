import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService, PagedResultDto } from '@systems/base';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core';
import { PartnerService } from 'src/app/core/customer/customer-client/partner.service';
import { NumberCompletenessCheckSortingDto, NumberCompletenessCheckDto, NumberCompletenessCheckService, ServiceTypeDto } from 'src/app/core/sysbiz/number-completeness-check.service';
import { ValueMapper } from 'src/app/core/sysbiz/value-mapper';
import { BaseListAdapterComponent } from 'src/app/shared/base-list-adapter-component';
import { SidebarToggleService } from 'src/app/shared/components/navigation-sidebar/sidebar-toggle.service';

@Component({
  selector: 'app-list-number-completeness',
  templateUrl: './list-number-completeness.component.html',
  styleUrls: ['./list-number-completeness.component.scss']
})
export class ListNumberCompletenessComponent
  extends BaseListAdapterComponent<NumberCompletenessCheckSortingDto, NumberCompletenessCheckDto, NumberCompletenessCheckService> implements OnInit {
    
  public searchName = '';
  public serviceName: string;
  public sectional = '';

  public numberCompletenessCheckSortingDto = NumberCompletenessCheckSortingDto;

  protected get defaultSorting(): NumberCompletenessCheckSortingDto {
    return NumberCompletenessCheckSortingDto.name;
  }

  public shouldShowPartnerDropdown: boolean;
  @Input() serviceType: ServiceTypeDto;

  constructor(
    public override service: NumberCompletenessCheckService,
    public partnerService: PartnerService,
    public sidebar: SidebarToggleService,
    modal: NgbModal,
    router: Router,
    zone: NgZone,
    route: ActivatedRoute,
    translationService: TranslateService,
    private valueMapper: ValueMapper,
    monitoringService: MonitoringService,
    authService: AuthService) {
    super(service, modal, monitoringService, translationService, router, zone, route, authService);
  }

  override ngOnInit(): void {
    //load nothing on init
  }

  public onAccordionOpen(): void {
    super.ngOnInit();

    this.authService.User.SubscribeSelectedCompanyUid().subscribe((x) => {
      if (x)
        this.refresh();
    });
  }

  getUrl(guid: string): string {
    if (this.serviceType === ServiceTypeDto.Postbox) {
      this.serviceName = 'invoices';
    }
    else {
      this.serviceName = 'archive';
    }
    return '/' + this.serviceName + '/number-check/' + guid + '/view';
  }

  public override checkParams(params: Params) {
    if (params['searchName']) {
      this.searchName = params['searchName'];
    } else {
      this.searchName = '';
    }

    if (params['sectional']) {
      this.sectional = params['sectional'];
    } else {
      this.sectional = '';
    }


    super.checkParams(params);
  }

  protected updateItem(dest: NumberCompletenessCheckDto, source: any): boolean {
    if (this.valueMapper.mapNumberCompletenessCheck(source, dest)) {
      this.refreshParams();
      return true;
    } else {
      return false;
    }
  }

  protected override generateQueryParams(): Params {
    return {
      serviceType: this.serviceType ? this.serviceType : null,
      searchName: this.searchName ? this.searchName : null,
      sectional: this.sectional ? this.sectional : null,
    };
  }

  protected override loadList(): Observable<PagedResultDto<NumberCompletenessCheckDto>> {
    return this.service.listByFilters(
      this.sorting,
      this.sortDirection,
      this.searchName,
      this.sectional,
      this.serviceType,
      this.result.currentPage,
      this.result.pageSize,
      this.authService.User.SelectedCompanyUid);
  }

  check(guid: string) {
    this.service.check(guid).subscribe(() => {
      this.refresh();
    });
  }
}
