import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'presuffix'
})
export class PresuffixPipe implements PipeTransform {

  transform(value: string, prefix: string, suffix: string, space: string = ''): string {
    let presuffixed: string = '';
    if (!this.empty(value)) {
      // Prefix
      if (!this.empty(prefix))
        presuffixed += prefix + space;

      // Value
      presuffixed += value;

      // Suffix
      if (!this.empty(suffix))
        presuffixed += space + suffix;
    }
    return presuffixed;
  }

  /**
   * Retrun true if value is empty
   * @param value Value to check
   */
  empty(value: any): boolean {
    return value === undefined || value === null || value === '' || value !== value || value.length == 0;
  }

}
