import { Injectable } from '@angular/core';
import { User } from './user';
import { SysbizAuthService } from '../sysbiz/sysbiz-auth.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Partner } from 'src/app/config/partner/_default';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private authService: SysbizAuthService,
    private router: Router
  ) { }

  /**
   * Authenticate the user in base of the username and password
   * @param username
   * @param password
   */
  async login(username: string, password: string): Promise<boolean> {
    this.resetCurrentUser();

    const token = await this.authService.internalAuthenticate(username, password)
      .toPromise();

    if (!token) {
      // token is empty => not valid
      return false;
    } else {
      await this.getUserInfos();
      return true;
    }
  }

  /**
   * Get additional infos about the user
   */
  async getUserInfos() {
    const user = await this.authService.getUser().toPromise().catch(() => null);

    if (user) {
      // user not found => some authentication error
      this.currentUserSubject.next(new User(user.id, user.userName, user.firstName,
        user.lastName, user.email, user.phoneNumber, user.selectedCompany, user.roles));
    } else {
      this.logout();
    }
  }

  /**
   * Logout the user
   */
  public logout() {
    this.authService.Token = null;
    this.resetCurrentUser();
    this.redirectToLogin();
  }

  /**
   * Check if the user is currently authenticated
   */
  public async loggedIn(): Promise<boolean> {
    const token = this.authService.Token;

    if (!token) {
      // token does not exists
      return false;
    } else if (this.User == null) {
      // try to get the current user
      await this.getUserInfos();
    }

    return token && this.User !== null;
    // return !this.jwtHelper.isTokenExpired(token);
  }

  /**
   * Getter for user
   * @returns User User
   */
  public get User() {
    return this.currentUserSubject.value;
  }

  /**
   * Getter for user
   * @returns User User
   */
  public subscribeUser() {
    return this.currentUserSubject.pipe();
  }

  /**
   * Reset the current user
   */
  public resetCurrentUser() {
    this.currentUserSubject.next(null);
  }

  /**
   * Role Based Access Control
   * @param roles Array<string> Array of allowed roles
   * @returns boolean User is authorized
   */
  public async rbac(roles: Array<string> = null): Promise<boolean> {
    if (await this.loggedIn()) {
      // Return true if no roles are specified
      if (roles && roles.length > 0) {
        if (!this.User) {
          return false;
        } else {
          // Loop through roles
          for (const role of this.User.Roles) {
            // Return true if role is in roles
            if (roles.indexOf(role) >= 0) {
              return true;
            }
          }
        }
      } else {
        return true;
      }
    }
    // If no condition matches, return false
    return false;
  }

  /**
   * Redirect user to login page
   */
  public redirectToLogin(returnUrl: string = null) {

      this.router.navigate(['/login'], {
        queryParams: {
          return: returnUrl
        }
      });
    return false;
  }
  public redirectTo(url: string, returnUrl: string = null): boolean {
    this.router.navigate([url], {
      queryParams: {
        return: returnUrl
      }
    });
    return false;
  }
}
