import { NgModule } from '@angular/core';

// Modules (invoices, archive and settings are missing because they are lazy loaded)
import { LoginModule } from './login/login.module';
import { ErrorsModule } from './errors/errors.module';
import { DashboardComponent } from './dashboard/dashboard.component';


@NgModule({
  imports: [
    LoginModule,
    ErrorsModule,
  ],
  declarations: [
  ]
})
export class FeaturesModule { }
