// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Ubuntu);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar.hidden {
  width: 38px !important;
  transition: width 0.3s;
}
.sidebar.hidden ::ng-deep .label, .sidebar.hidden ::ng-deep .nav-item-label {
  display: none;
}
.sidebar.hidden ::ng-deep .btn-block {
  padding: 1rem 0.5rem;
  transition: padding 0.3s;
}
.sidebar.hidden ::ng-deep .nav-item {
  border: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/navigation-sidebar/navigation-sidebar.component.scss"],"names":[],"mappings":"AAEA;EACI,sBAAA;EAEA,sBAAA;AAAJ;AAIQ;EAEI,aAAA;AAHZ;AAMQ;EACI,oBAAA;EAEA,wBAAA;AAJZ;AAOQ;EACI,YAAA;AALZ;;AAUA;EACI,aAAA;AAPJ","sourcesContent":["@import './node_modules/@systems/base/assets/style/variables.scss';\n\n.sidebar.hidden {\n    width: $sibebar-collapsed-width !important;\n    -webkit-transition: width 0.3s;\n    transition: width 0.3s;\n\n    ::ng-deep {\n\n        & .label,\n        & .nav-item-label {\n            display: none;\n        }\n\n        & .btn-block {\n            padding: 1rem 0.5rem;\n            -webkit-transition: padding 0.3s;\n            transition: padding 0.3s;\n        }\n\n        .nav-item {\n            border: none;\n        }\n    }\n\n}\n.sidebar::-webkit-scrollbar{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
