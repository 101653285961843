import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-completeness-check',
  templateUrl: './number-completeness-check.component.html',
  styleUrls: ['./number-completeness-check.component.scss']
})
export class NumberCompletenessCheckComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
  }

}
