import { LanguageConfig } from "src/app/core/interfaces/language-config";

import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';

export const Language: Array<LanguageConfig> = [
  {
    id: 'de',
    locale: {
      name: 'de',
      localeData: localeDe,
      localeExtraData: localeDeExtra
    },
    formats: {
      date: 'dd.MM.yyyy'
    }
  },
  {
    id: 'it',
    locale: {
      name: 'it',
      localeData: localeIt,
      localeExtraData: localeItExtra
    },
    formats: {
      date: 'dd.MM.yyyy'
    }
  },
  {
    id: 'en',
    locale: {
      name: 'en',
      localeData: localeEn,
      localeExtraData: localeEnExtra
    },
    formats: {
      date: 'MM/dd/yyyy'
    }
  }
];