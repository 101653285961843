// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-item-label {
  position: relative;
  display: inline-block;
}
.nav-item-label .badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  font-size: 0.6em;
  padding: 0.25em 0.4em;
}

.statisticsIcon {
  margin-left: 2px;
}

.statistics {
  margin-left: 2px;
}

@media (max-width: 575px) {
  .navbar-collapse.show .nav-item-label {
    display: inline-block;
  }
  .navbar-collapse.show .nav-link-label {
    display: inline-block !important;
  }
  .navbar-collapse.show .nav-link {
    margin-top: 7px;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}
.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  padding: 0.2em 0.4em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/navigation-main/navigation-main.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,+BAAA;EACA,gBAAA;EACA,qBAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE;IACE,qBAAA;EAAF;EAGA;IACE,gCAAA;EADF;EAIA;IACE,eAAA;EAFF;EAKA;IACE,yQAAA;EAHF;AACF;AAMA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,+BAAA;EACA,kBAAA;EACA,oBAAA;AAJF","sourcesContent":[".nav-item-label {\n  position: relative;\n  display: inline-block;\n\n  .badge {\n    position: absolute;\n    top: 0;\n    right: 0;\n    transform: translate(50%, -50%);\n    font-size: 0.6em;\n    padding: 0.25em 0.4em;\n  }\n}\n\n.statisticsIcon {\n  margin-left: 2px;\n}\n\n.statistics {\n  margin-left: 2px;\n}\n\n@media (max-width: 575px) {\n  .navbar-collapse.show .nav-item-label {\n    display: inline-block\n  }\n\n  .navbar-collapse.show .nav-link-label {\n    display: inline-block !important;\n  }\n\n  .navbar-collapse.show .nav-link {\n    margin-top: 7px;\n  }\n\n  .navbar-toggler-icon {\n    background-image: url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E\");\n  }\n}\n\n.notification-badge {\n  position: absolute;\n  top: 0;\n  right: 0;\n  transform: translate(50%, -50%);\n  border-radius: 50%;\n  padding: 0.2em 0.4em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
