import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
    name: 'currencySymbol',
    pure: false
})
export class CurrencySymbolPipe implements PipeTransform {

    constructor(
        private translate: TranslateService
    ) { }

    transform(currencyCode?: string, display?: 'wide' | 'narrow', locale?: string): string | undefined {
        // Perform transformation only if a value is set
        if (currencyCode != null) {
            const fcLocale = locale ? locale : this.translate.currentLang.substr(0, 2);
            const fcCurrencyCode = currencyCode ? currencyCode : 'EUR';

            let fcCurrency;
            switch (display) {
                case 'wide':
                    fcCurrency = getCurrencySymbol(fcCurrencyCode, 'wide', fcLocale);;
                    break;
                case 'narrow':
                    fcCurrency = getCurrencySymbol(fcCurrencyCode, 'narrow', fcLocale);
                    break;
                default:
                    fcCurrency = getCurrencySymbol(fcCurrencyCode, 'narrow', fcLocale);
            }

            return fcCurrency;
        } else
            return undefined;
    }
}
