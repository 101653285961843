import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, PagedResultDto } from '@systems/base';
import { map, Observable } from 'rxjs';
import { CustomerDto } from '../../sysbiz/sysbiz-customer.service';
import { AdditionalSettingsDto } from './company-details/additional-settings';
import { ConservazioneDto } from './company-details/conservation';
import { ConservationTransmissionSettingsDto } from './company-details/conservation-transmission-settings';
import { ContattiDto } from './company-details/contatti';
import { ContractTransmissionDto } from './company-details/contract-transmission';
import { DatiAnagraficiDto } from './company-details/dati-anagrafici';
import { DatiCassaPrevidenzialeDto } from './company-details/dati-cassa-previdenziale';
import { DatiPagamentoDto } from './company-details/dati-pagamento';
import { DatiRitenutaDto } from './company-details/dati-ritenuta';
import { IscrizioneREADto } from './company-details/iscrizione-rea';
import { NotificationSubscriptionDto } from './company-details/notification-subscription';
import { RappresentanteFiscaleDto } from './company-details/rappresentante-fiscale';
import { ReceiptIncomingInvoicesDto } from './company-details/receipt-incoming-invoices';
import { SectionalDto } from './company-details/sectional';
import { SedeDto } from './company-details/sede';
import { StabileOrganizzazioneDto } from './company-details/stabile-organizzazione';
import { BaseFilterDto, WriteBaseModelDto } from './models';
import { SortDirectionDto } from './sort-direction';
import { Option } from 'src/app/features/invoices/components/invoice/select/select.component';
import { TranslationService } from './translation.service';
import { RestApiClientAdapterService } from '../../sysbiz/rest-api-client-adapter.service';
import { BankCoordinatesDto } from './company-details/bank-coordinates';
import { CUSTOMERAPICLIENT_CONFIG } from '../../sysbiz/config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends RestApiClientAdapterService<CompanyDto, DetailsCompanyDto, WriteCompanyDto> {

  constructor(
    protected override http: HttpClient,
    authService: AuthService,
    private translationService: TranslationService
  ) {
    super(CUSTOMERAPICLIENT_CONFIG, http, authService, 'companies');
  }

  /**
   * Create a single item
   *
   * @param item item with the values to create
   */
  public override create(item: WriteCompanyDto): Observable<DetailsCompanyDto> {
    return this.http.post<DetailsCompanyDto>(this.getUrl(`companies`), item,
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response"
      }).pipe(
        map((resp: any) => {
          this.updateTokenFromResponse(resp);
          return resp.body;
        }));
  }

  public getTranslationByType(enumeration: Array<any>, type: string, withKey: boolean = true): Array<Option> {
    let stati = new Array<Option>();
    enumeration.forEach((value) => {
      this.translationService.get(`${type}.types.${value}`).subscribe((x) => {
        stati.push({ value: value, description: (withKey ? value + ' - ' : '') + x });
      });
    });
    return stati;
  } 


  public listByFilters(
    sorting?: CompanySortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    isActive?: boolean,
    pageNumber?: number,
    pageSize?: number,
    includeCustomer: boolean = false,

  ): Observable<PagedResultDto<CompanyDto>> {

    let params = new HttpParams();

    if (includeCustomer) {
      params = params.append('includeCustomer', includeCustomer as any);
    }
    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('searchName', searchText as any);
    }
    if (isActive || isActive == false) {
      params = params.append('isActive', isActive as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }

    return this.http.get<PagedResultDto<CompanyDto>>(this.getUrl(`companies`),
      {
        observe: 'response',
        headers: {
          authenticate: this.Token
        },
        params: params,
        responseType: 'json'
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public getCompanyCount(): Observable<number> {
    return this.http.get<number>(this.getUrl(`companies/count`),
      {
        observe: 'response',
        headers: {
          authenticate: this.Token
        },
        responseType: 'json'
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }
  public listCompaniesFromUser(
    userGuid?: string,
    sorting?: CompanySortingDto,
    sortDirection?: SortDirectionDto,
    searchText?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PagedResultDto<CompanyDto>> {

    let params = new HttpParams();

    if (sorting) {
      params = params.append('sorting', sorting as any);
    }
    if (sortDirection) {
      params = params.append('sortDirection', sortDirection as any);
    }
    if (searchText) {
      params = params.append('searchName', searchText as any);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber as any);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize as any);
    }
    if (userGuid) {
      params = params.append('userGuid', userGuid as any);
    }
    return this.http.get<PagedResultDto<CompanyDto>>(this.getUrl(`companies/getByUserGuid`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
        params
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public listCompanyLicenses(guid: string) {
    return this.http.get<Array<string>>(this.getUrl(`companies/${guid}/companyLicenses`), { headers: { 'authenticate': this.Token }, observe: 'response' })
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
  }

  public updateLogo(companyGuid: string, item: File) {
    const formData = new FormData();
    formData.append("logo", item);
    return this.http.put(this.getUrl(`companies/${companyGuid}/logo`), formData, { headers: { 'authenticate': this.Token }, observe: 'response', responseType: 'json' })
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
  }

  public createLogo(companyGuid: string, item: File) {
    const formData = new FormData();
    formData.append("logo", item);
    return this.http.post(this.getUrl(`companies/${companyGuid}/logo`), formData, { headers: { 'authenticate': this.Token }, observe: 'response', responseType: 'json' })
      .pipe(map(res => {
        this.updateTokenFromResponse(res);
        return res.body;
      }));
  }

  public loadLogo(companyGuid: string) {
    return this.http.get(this.getUrl(`companies/${companyGuid}/logo`),
      {
        responseType: 'blob',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public deleteLogo(companyGuid: string) {
    return this.http.delete(this.getUrl(`companies/${companyGuid}/logo`),
      {
        responseType: 'json',
        headers: {
          authenticate: this.Token
        },
        observe: "response",
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

  public logoExists(companyGuid: string): Observable<boolean> {
    return this.http.get<number>(this.getUrl(`companies/${companyGuid}/logo/exists`),
      {
        observe: 'response',
        headers: {
          authenticate: this.Token
        },
        responseType: 'json'
      }).pipe(map((resp: any) => {
        this.updateTokenFromResponse(resp);
        return resp.body;
      }));
  }

}



export interface DetailsCompanyDto extends CompanyDto {

}

export interface CompanyDto extends WriteCompanyDto {
  guid: string;
  customer: CustomerDto;
  hasLogo: boolean;
  item?: any;
}

export interface WriteCompanyDto extends WriteBaseModelDto {
  isActive: boolean;
  companyType: CompanyTypeDto;
  customerGuid: string;
  searchName: string;

  datiAnagrafici: DatiAnagraficiDto;
  sede: SedeDto;
  stabileOrganizzazione: StabileOrganizzazioneDto | null;
  iscrizioneREA: IscrizioneREADto | null;
  contatti: ContattiDto;
  riferimentoAmministrazione: string | null;

  rappresentanteFiscale: RappresentanteFiscaleDto | null;
  datiRitenuta: DatiRitenutaDto | null;
  datiPagamento: DatiPagamentoDto | null;
  datiCassaPrevidenziale: DatiCassaPrevidenzialeDto | null;

  contractTransmission: ContractTransmissionDto;
  notificationSubscriptions: NotificationSubscriptionDto[];
  additionalSettings: AdditionalSettingsDto;
  sectionals: SectionalDto[];
  conservationTransmissionSettings: ConservationTransmissionSettingsDto;
  receiptIncomingInvoices: ReceiptIncomingInvoicesDto;
  conservazione: ConservazioneDto;
  outgoingAutoPaidModalities: any[];
  incomingAutoPaidModalities: any[];

  fiscalYear: Date;
  customerNumber: number;

  bankCoordinates: BankCoordinatesDto[];
}

export enum CompanyTypeDto {
  PublicOffice = "PublicOffice",
  Company = "Company"
}

export interface CompanyFilterDto extends BaseFilterDto {
  sorting: CompanySortingDto | undefined;
  sortDirection: SortDirectionDto | undefined;
  searchName: string | undefined;
  pageNumber: number | undefined;
  pageSize: number | undefined;
}

export enum CompanySortingDto {
  IsActive = "IsActive",
  SearchName = "SearchName",
  VatNumber = "VatNumber",
  TaxCode = "TaxCode"
}
