import { PartnerConfig } from "src/app/core/interfaces/partner-config";

export const Partner: PartnerConfig = {
  id: 'dev',
  title: {
    de: 'Sysbiz Development Portal [de]',
    it: 'Sysbiz Development Portal [it]'
  },
  portalUrl: 'https://dev-bs.systems.bz',
  apiUrl: 'https://dev-bs.systems.bz',
  oldPortalUrl: 'https://dev-bs.systems.bz/-/',
  backlink: true  
}
