import { NgModule, ModuleWithProviders } from '@angular/core';
import { SysbizConfig, SysbizConfigService } from './sysbiz-config.service';
@NgModule()
export class SysbizModule {
  static forRoot(config: SysbizConfig): ModuleWithProviders<SysbizModule> {
    return {
      ngModule: SysbizModule,
      providers: [
        {
          provide: SysbizConfigService,
          useValue: config
        },
      ]
    }
  }
}
