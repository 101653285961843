import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { CookieComponent, ImpressumComponent } from '@systems/base';
import { NotFoundComponent } from 'src/app/features/errors/not-found/not-found.component';
import { AuthGuardService } from './core';
import { SystemsPartnerGuardService } from './core/auth/systems-partner-guard.service';
import { GotoStartpageComponent } from './core/goto-startpage/goto-startpage.component';
import { MainComponent } from './shared/main/main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        component: GotoStartpageComponent
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'invoices',
        loadChildren: () => import('./features/invoices/invoices.module').then(m => m.InvoicesModule)
      },
      {
        path: 'archive',
        loadChildren: () => import('./features/archive/archive.module').then(m => m.ArchiveModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'statistics',
        loadChildren: () => import('./features/statistics/statistics.module').then(m => m.StatisticsModule)
      },

      {
        path: 'banking',
        loadChildren: () => import('./features/banking/banking.module').then(m => m.BankingModule),
        data: {
          roles: ['SystemsAdmin', 'BANKING.READ']
        }
      },
      {
        path: 'document-signer',
        loadChildren: () => import('./features/document-signer/document-signer.module').then(m => m.DocumentSignerModule),
        data: {
          userIsAGuest: true
        }
      },
      {
        path: 'impressum',
        component: ImpressumComponent,
        canActivate: [SystemsPartnerGuardService],
      },
      {
        path: 'cookie',
        component: CookieComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      },
    ]
  }
];

const options: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollOffset: [0, 50],
  scrollPositionRestoration: 'enabled'
}
@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
