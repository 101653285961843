import { Component, OnInit } from '@angular/core';
import { NewsDto, SysbizCustomerService } from 'src/app/core/sysbiz/sysbiz-customer.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from 'src/app/core';
import { BasePageComponent, MonitoringService } from '@systems/base';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent extends BasePageComponent implements OnInit {
  private keyStorage = 'newsRead';

  news: NewsDto[];
  activeCard: NewsDto;

  openNewsIndex?: number = undefined;

  constructor(
    modal: NgbModal,
    monitoringService: MonitoringService,
    translationService: TranslateService,
    private customerService: SysbizCustomerService,
    private translate: TranslateService,
    private requests: RequestService
  ) {
    super(modal, monitoringService, translationService)
  }

  ngOnInit() {

    this.refreshList();

    this.translate.onLangChange.subscribe(() => {
      this.refreshList();
    });
  }

  private refreshList() {
    this.news = [];

    const callNews = this.customerService.getNews(this.translate.currentLang.substr(0, 2));

    this.requests.addRequest('news-list', callNews).subscribe(x => {
      this.news = this.markAsNew(x);
    });
  }

  public setNewsAsRead(news: NewsDto) {
    if (news.new) {
      localStorage.setItem(this.keyStorage, localStorage.getItem(this.keyStorage) + ',' + news.id);
      news.new = false;
    }
  }

  public markAsNew(news: NewsDto[]) {

    let newReadIds = '';
    const lastReadIds = localStorage.getItem(this.keyStorage);

    if (lastReadIds) {
      const res = lastReadIds.split(',');

      // check if the news are just read
      news.forEach(newss => {
        if(newss.id)
        newss.new = res.indexOf(newss.id) < 0;
        if (!newss.new) {
          // just read => save it
          newReadIds += newss.id + ',';
        }
      });
    } else {
      // mark all news as new
      news.forEach(element => {
        element.new = true;
      });
    }

    // remove last ','
    if (newReadIds.length > 1) {
      newReadIds = newReadIds.substring(0, newReadIds.length - 1);
    }

    localStorage.setItem(this.keyStorage, newReadIds);
    return news;
  }
}

