import { Component } from '@angular/core';
import { CustomTitleService } from 'src/app/core/custom-title/custom-title.service';
import { Partner } from 'src/app/config/partner/_default';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  readonly partner = Partner.id;

  constructor(
    private title: CustomTitleService
  ) {
    this.title.setTitle('error.page.not-found.title', true);
  }

}
