import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'customNumber',
  pure: false
})
export class CustomNumberPipe implements PipeTransform {

  private value: string;

  constructor(
    private translate: TranslateService
  ) { }

  transform(value: any, digitsInfo?: string, locale?: string): string | null {
    // Perform transformation only if a value is set
    if (value) {
      const fcLocale = locale ? locale : this.translate.currentLang.substr(0, 2);
      const fcDigitsInfo = digitsInfo ? digitsInfo : '0.2';
      this.value = formatNumber(value, fcLocale, fcDigitsInfo);
      return this.value;
    } else
      return null;
  }

}
