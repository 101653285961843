import { BehaviorSubject } from 'rxjs';

export class User {
  private selectedCompanyUidSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private id: number;
  private name: string;
  private firstName?: string;
  private lastName?: string;
  private email?: string;
  private roles?: Array<string>;
  private phone?: string;

  constructor(id: number, name: string, firstName?: string, lastName?: string, email?: string, phone?: string, selectedCompanyUid?: string, roles?: Array<string>){
    this.id = id;
    this.name = name;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.SelectedCompanyUid = selectedCompanyUid;
    this.roles = roles;
  }

	/**
	 * Getter
	 */
  get Id() {
    return this.id;
  }

  get Name() {
    return this.name;
  }

  get FirstName() {
    return this.firstName;
  }

  get LastName() {
    return this.lastName;
  }

  get Email() {
    return this.email;
  }

  get Roles() {
    return this.roles;
  }

  get Phone() {
    return this.phone;
  }


  get SelectedCompanyUid() {
    return this.selectedCompanyUidSubject.value;
  }

  set SelectedCompanyUid(selectedCompanyUid: string) {
    this.selectedCompanyUidSubject.next(selectedCompanyUid);
  }

  public SubscribeSelectedCompanyUid() {
    return this.selectedCompanyUidSubject.pipe();
  }

  public get FullName() {
    if (this.firstName || this.lastName)
      return this.lastName + " " + this.firstName;
    else
      return this.name;
  }
}
