import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService } from '@systems/base';
import { DetailsNumberCompletenessCheckDto, NumberCompletenessCheckService, ServiceTypeDto } from 'src/app/core/sysbiz/number-completeness-check.service';
import { SysbizAuthService } from 'src/app/core/sysbiz/sysbiz-auth.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BaseDetailAdapterComponent } from 'src/app/shared/base-detail-adapter-component';
import { Location } from '@angular/common';
import { SidebarToggleService } from 'src/app/shared/components/navigation-sidebar/sidebar-toggle.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-details-number-completeness',
  templateUrl: './details-number-completeness.component.html',
  styleUrls: ['./details-number-completeness.component.scss']
})
export class DetailsNumberCompletenessComponent extends BaseDetailAdapterComponent<DetailsNumberCompletenessCheckDto, NumberCompletenessCheckService> implements OnInit, OnDestroy {
  public addClicked: boolean;
  private subscription: Subscription;
  public checkGuid: string | null = "";
  public isMenuCollapsed: boolean;
  public maxLenghtDatabase: boolean = false;

  constructor(
    public override service: NumberCompletenessCheckService,
    public sysbizAuthService: SysbizAuthService,
    public sidebar: SidebarToggleService,
    modal: NgbModal,
    translationService: TranslateService,
    activatedRoute: ActivatedRoute,
    location: Location,
    monitoringService: MonitoringService,
    authService: AuthService) {
    super(service, modal, monitoringService, translationService, activatedRoute, location, authService);
    this.subscription = sidebar.GetValue().subscribe(_ => {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    });
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.isMenuCollapsed = this.sidebar.isMenuCollapsed;
    
  }

  protected override validateResult(): void {
    this.checkMaxLenghtDatabase();
  }
  
  public loadResults(){
    this.checkGuid = this.activatedRoute.snapshot.paramMap.get('guid');
    if(this.checkGuid){
      this.service.get(this.checkGuid).subscribe(x =>{
        this.item = x;
      });
    }
  }

  check() {
    if(this.item){
      this.service.check(this.item.guid).subscribe((x) => {
        this.item = x;
      });
    }
  }

  checkMaxLenghtDatabase() {
    if (this.item?.lastExecution && this.item.lastExecution.missingDocumentNumbers.length < this.item.lastExecution.missingDocumentCount) {
      this.maxLenghtDatabase = true;
    } else {
      this.maxLenghtDatabase = false;
    }
  }

}
