import { PartnerConfig } from "src/app/core/interfaces/partner-config";

export const Partner: PartnerConfig = {
  id: 'systems',
  title: {
    de: 'Systems SysBiz',
    it: 'Systems SysBiz'
  },
  portalUrl: 'https://bs.systems.bz',
  apiUrl: 'https://bs-api.systems.bz',
  oldPortalUrl: 'https://bs.systems.bz/-/',
  backlink: true,
  pathPrefix: ''
}
