import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';

import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordExpiredComponent } from './password-expired/password-expired.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AngularBaseModule } from '@systems/base';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'passwordExpired',
    component: PasswordExpiredComponent
  },
  {
    path: 'passwordReset',
    component: PasswordResetComponent
  }
];

@NgModule({
  imports: [
    AngularBaseModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [LoginComponent, PasswordExpiredComponent, PasswordResetComponent]
})
export class LoginModule { }
