import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { AuthService, RequestService } from 'src/app/core';
import { RequestError } from 'src/app/core/request';
import { SysbizAuthService } from 'src/app/core/sysbiz/sysbiz-auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  public form: FormGroup;
  public formData: { userName: string | null } = {
    userName: null,
  };
  
  public errorLoginMessage: string;
  public success: boolean = false;
  
  constructor(  
    private authService: AuthService,
    private sysbizAuthService: SysbizAuthService,
    private requests: RequestService) { }

    ngOnInit() {
     
      // Create FormGroup
      this.form = new FormGroup(
        {
          userName: new FormControl(this.formData.userName, [
            Validators.required
          ])
        }
      );      
  }

  public async requestResetPassword(userName: string) {  
    this.errorLoginMessage = null;
    let call$ = from(this.sysbizAuthService.requestNewPassword(userName));
    this.requests.addRequest('requestNewPassword', call$).subscribe(
      x => {             
          this.success = true;           
      },
      (err: RequestError) => 
      {      
          this.errorLoginMessage = err.translatedMessage;        
      }
    );    
  }

  public goToLogin(){
    this.authService.redirectToLogin();
  }

  public get userName() {
    return this.form.get('userName');
  }
}
