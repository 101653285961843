import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { BankingCompanyService } from '../banking/company.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankingRedirectGuard implements CanActivate, CanActivateChild {
  
  public onboardingCompleted: boolean = false;
  public selectedCompanyGuid: string;

  constructor(
    private authService: AuthService,
    private companyService: BankingCompanyService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.validate(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.validate(route, state);
  }

  /**
   * Checks whether a role is allowed to see the route
   * @param route ActivatedRouteSnapshot Route information provided by router
   */
  private validate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (route.data.exceptions && route.data.exceptions.queryParams.every((val: string) => route.queryParamMap.keys.indexOf(val) >= 0)) {
      return true;
    }

    // No role restrictions → user authenticated?
    return this.authService.loggedIn().then(loggedIn => {
      if (loggedIn) {
        // User is authenticated
        if (route.data.roles) {
          // Route-roles specified → let RBAC do its magic
          return this.authService.rbac(route.data.roles).then(authorized => {
            if (authorized) {
              return firstValueFrom(this.authService.subscribeUser()).then(user => {
                if (user && user.SelectedCompanyUid) {
                  return firstValueFrom(this.companyService.details(user.SelectedCompanyUid)).then(companyDetails => {
                    if (companyDetails && companyDetails.externalOnboardingState === 'COMPLETED_SUCCESS') {
                      this.onboardingCompleted = true;
                      this.selectedCompanyGuid = user.SelectedCompanyUid;
                      return true; 
                    } else {
                      if (user.Roles && user.Roles.includes('BANKING.AUTOMATIC')) {
                        this.authService.redirectTo('/banking/onboarding');
                        return false; 
                      }
                      return true; 
                    }
                  });
                } 
                else{
                  this.authService.redirectTo('/banking/transactions');
                  return true;
                }
              });
            } else {
              // User not authorized → reroute to login page
              this.authService.redirectToLogin(state.url);
              return false;
            }
          });
        } else {
          return true;  // No roles specified, allow access
        }
      } else {
        // User not authenticated → reroute to login page
        this.authService.redirectToLogin(state.url);
        return false;
      }
    });
  }
}
